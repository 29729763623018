import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function DashboardBusiness () {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Dashboard');
  }, []);

  return (
    <section className="container-fluid">
      <section className="row">
        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img className="card-img-top img-fluid" src="/assets/images/dashboard/insights.jpg" alt="Card image cap" />
            <div className="card-body text-center">
              <h4 className="card-title">Insights</h4>
              <a href="/admin/insight" className="btn btn-bordered-primary">NAVEGAR</a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img className="card-img-top img-fluid" src="/assets/images/dashboard/operations.jpg" alt="Card image cap" />
            <div className="card-body text-center">
              <h4 className="card-title">Operaciones</h4>
              <a href="/admin/operations" className="btn btn-bordered-primary">NAVEGAR</a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img className="card-img-top img-fluid" src="/assets/images/dashboard/driver.jpg" alt="Card image cap" />
            <div className="card-body text-center">
              <h4 className="card-title">Conductores</h4>
              <a href="/admin/driver" className="btn btn-bordered-primary">NAVEGAR</a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img className="card-img-top img-fluid" src="/assets/images/dashboard/vehicles.jpg" alt="Card image cap" />
            <div className="card-body text-center">
              <h4 className="card-title">Vehiculos</h4>
              <a href="/admin/vehicle" className="btn btn-bordered-primary">NAVEGAR</a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img className="card-img-top img-fluid" src="/assets/images/dashboard/users.jpg" alt="Card image cap" />
            <div className="card-body text-center">
              <h4 className="card-title">Usuarios</h4>
              <a href="/admin/user" className="btn btn-bordered-primary">NAVEGAR</a>
            </div>
          </article>
        </div>
      </section>
    </section>
  );
}

export default DashboardBusiness;