import BaseApi from '../base/apiBase.service'

export default class SecurityService extends BaseApi {
  constructor (args) {
    super(args)

    this.service_uri = {
      login: '/security/login',
      logout: '/security/logout'
    }
  }

  async login(data) {
    return super.post(data, this.service_uri.login);
  }

  async logout(data) {
    return super.post(data, this.service_uri.logout);
  }
}