import React, { useEffect } from 'react';

function SetUI() {
    useEffect(() => {
        const element = document.querySelector('body');
        element.classList.add('authentication-bg')
    });
}

function AuthLockscreen() {
    SetUI();

    return (
        <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="text-center">
                <a href="/">
                    <img src="/assets/images/logo-dark.svg" alt="" height="22" className="mx-auto" />
                </a>
                <p className="text-muted mt-2 mb-4">La nueva forma de pedir servicios de transporte</p>
            </div>
            <div className="card">

                <div className="card-body p-4">

                    <div className="text-center mb-4">
                        <h4 className="text-uppercase mt-0 mb-4">Bienvenido de nuevo</h4>
                        <img src="/assets/images/characters/character-23.svg" width="88" alt="user-image" className="rounded-circle img-thumbnail bg-warning bg-moved-down" />
                        <p className="text-muted my-4">Ingrese tu contraseña para acceder al administrador.</p>

                    </div>

                    <form action="#">

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Contraseña</label>
                            <input className="form-control" type="password" required="" id="password" placeholder="Ingresa tu contraseña" />
                        </div>

                        <div className="mb-0 text-center d-grid">
                            <a href="/admin" className="btn btn-primary" type="submit"> Ingresar </a>
                        </div>

                    </form>

                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12 text-center">
                    <p className="text-muted">No eres tú? regresar a <a href="/auth/login" className="text-dark ms-1"><b>Iniciar sesión</b></a></p>
                </div>
            </div>

        </div>
    );
}

export default AuthLockscreen;