import React from 'react';
import { useEffect } from 'react';

function SetUI() {
    useEffect(() => {
        const element = document.querySelector('body');
        element.classList.add('authentication-bg')
    });
}

function MaintenanceGeneral() {
    SetUI();

    return (
        <div className="col-lg-12">

            <div className="text-center">
                <div className="text-center w-50 m-auto">
                    <div className="auth-logo">
                        <a href="/" className="text-center">
                            <span className="logo-lg">
                                <img src="/assets/images/utilities/cat-2.svg" alt="" className="img-fluid" />
                            </span>
                        </a>
                    </div>
                </div>
                <h3 className="mt-4">No se puede cargar!</h3>
                <p className="text-muted">Estamos haciendo que el sistema sea más increíble. Volveremos pronto.</p>

                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="avatar-md rounded-circle bg-soft-primary mx-auto">
                                    <i className="ti-stats-down font-22 avatar-title text-primary"></i>
                                </div>
                                <h5 className="text-uppercase mt-3">¿Por qué está caído el sitio?</h5>
                                <p className="font-13 text-muted mb-0">Si regularmente accedes a esta sección seguramente estamos mejorando el sistema, de lo contratio, accediste a una opción inhabilitada.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="avatar-md rounded-circle bg-soft-primary mx-auto">
                                    <i className="ti-timer font-22 avatar-title text-primary"></i>
                                </div>
                                <h5 className="text-uppercase mt-3">¿Qué es el tiempo de inactividad?</h5>
                                <p className="font-13 text-muted mb-0">Cuando estamos haciendo increible el sitio pueden haber caídas repentinas y en unos instantes todo estará solucionado.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="avatar-md rounded-circle bg-soft-primary mx-auto">
                                    <i className="ti-headphone-alt font-22 avatar-title text-primary"></i>
                                </div>
                                <h5 className="text-uppercase mt-3">¿Necesita soporte?</h5>
                                <p className="font-13 text-muted mb-0">Si necesitas acceder lo más pronto posible pero es imposible no dudes en contactarnos al siguiente email <a href="mailto:#" className="text-muted font-weight-medium">no-reply@domain.com</a></p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default MaintenanceGeneral;