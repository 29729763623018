import React from "react";
import { useAuth } from "../../hooks/useAuth.hook";

function SidebarAdmin () {
  const { user } = useAuth();

  return (
    <div className="left-side-menu">

      <div className="h-100" data-simplebar>

        {/* <!-- User box --> */}
        <div className="user-box text-center">

          <img src={user.profile_pic_uri || '/assets/images/characters/character-unknown.svg'} alt="user-img" title="my user photo" className="rounded-circle img-thumbnail avatar-xxl bg-warning bg-pic-profile" />
          <div className="dropdown">
            <a href="#" className="user-name dropdown-toggle h5 mt-2 mb-1 d-block" data-bs-toggle="dropdown" aria-expanded="false">{user.fullname}</a>
            <div className="dropdown-menu user-pro-dropdown">
              <a href="/account/overview" className="dropdown-item notify-item">
                <i className="fe-user me-1"></i>
                <span>Mi cuenta</span>
              </a>
              <a href="/auth/lock-screen" className="dropdown-item notify-item">
                <i className="fe-lock me-1"></i>
                <span>Bloquear</span>
              </a>
              <a href="/auth/logout" className="dropdown-item notify-item">
                <i className="fe-log-out me-1"></i>
                <span>Salir</span>
              </a>
            </div>
          </div>

          <p className="text-muted left-user-info">{user.role.title}</p>

          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/auth/lock-screen">
                <i className="mdi mdi-power"></i>
              </a>
            </li>
          </ul>
        </div>

        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className="menu-title">Navegación</li>

            <li>
              <a href="/admin/">
                <i className="mdi mdi-view-dashboard-outline"></i>
                <span> Dashboard </span>
              </a>
            </li>

            <li className="menu-title mt-2">Apps</li>

            <li>
              <a href="/admin/insight">
                <i className="mdi mdi-finance"></i>
                <span> Insights </span>
              </a>
            </li>
            <li>
              <a href="/admin/operations">
                <i className="mdi mdi-face-agent"></i>
                <span> Operaciones </span>
              </a>
            </li>
            <li>
              <a href="/admin/driver">
                <i className="mdi mdi-fingerprint"></i>
                <span> Conductores </span>
              </a>
            </li>

            <li>
              <a href="/admin/vehicle">
                <i className="mdi mdi-rickshaw"></i>
                <span> Vehiculos </span>
              </a>
            </li>

            <li>
              <a href="/admin/client">
                <i className="mdi mdi-account-heart"></i>
                <span> Clientes </span>
              </a>
            </li>

            <li>
              <a href="/admin/business">
                <i className="mdi mdi-storefront-outline"></i>
                <span> Negocios </span>
              </a>
            </li>

            <li>
              <a href="/admin/user">
                <i className="mdi mdi-account-group"></i>
                <span> Usuarios </span>
              </a>
            </li>

          </ul>

        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default SidebarAdmin;