import BaseApi from '../base/apiBase.service'

export default class ClientService extends BaseApi {
  constructor (args) {
    super(args)

    this.service_uri = {
      get: '/identity/client',
      create: '/identity/client/',
      update: '/identity/client/'
    }
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }
}