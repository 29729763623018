import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import OffCanvasData from '../../components/utilities/OffCanvasData';
import { ClientService } from '../../services/index';

function ClientList () {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Clientes');
  }, []);

  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const entityService = new ClientService();
      const entityResponse = await entityService.getByParameters({
        all: true
      });

      if (entityResponse && entityResponse.result) {
        setEntities(entityResponse.result);
        console.log(entityResponse)
      }
    }

    fetchData().catch(console.error);
  }, []);

  return (
    <section className="container-fluid">
      <div className="row">
        <div className="col-12">
          <article className="card">
            <section className="card-body">
              <header>
                <section className="dropdown float-end">
                  <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="/admin/client/create" className="dropdown-item"><i className="mdi mdi-plus me-1"></i> Crear nuevo</a>
                  </div>
                </section>
                <h4 className="mt-0 header-title">Centro de control</h4>
                <p className="text-muted font-14 mb-3">
                  Los vehículos son todos aquellos que están siendo soportados en tu plataforma.
                </p>
              </header>

              <section className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Identificación</th>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Teléfono</th>
                      <th>Puntaje</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities.map(entity => (
                      <tr key={entity.id}>
                        <th scope="row">{entity.dni}</th>
                        <td>{`${entity.firstname} ${entity.lastname}`}</td>
                        <td>{entity.email}</td>
                        <td>{entity.phone}</td>
                        <td>{entity.score}</td>
                        <td>
                          <OffCanvasData className="btn btn-purple waves-effect waves-light btn-xs"
                            icon="mdi mdi-open-in-new"
                            id={entity.id}
                            title={`${entity.firstname} ${entity.lastname}`}
                            content="Detalles"
                            entity={entity} />
                          <a href={`/admin/client/update?id=${entity.id}`} className="btn btn-purple waves-effect waves-light btn-xs mx-1 my-1">
                            <i className="mdi mdi-square-edit-outline"></i> Editar
                          </a>
                          <a href={`/admin/client/delete?id=${entity.id}`} className="btn btn-danger waves-effect waves-light btn-xs">
                            <i className="mdi mdi-delete"></i> Eliminar
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </section>
          </article>
        </div>
      </div>
    </section>
  )
}

export default ClientList;