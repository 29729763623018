export const PROFILE_PICTURES = [
  {
    key: 'pic-1',
    uri: '/assets/images/characters/character-1.svg',
    name: 'Character 1'
  },
  {
    key: 'pic-2',
    uri: '/assets/images/characters/character-2.svg',
    name: 'Character 2'
  },
  {
    key: 'pic-3',
    uri: '/assets/images/characters/character-3.svg',
    name: 'Character 3'
  },
  {
    key: 'pic-4',
    uri: '/assets/images/characters/character-4.svg',
    name: 'Character 4'
  },
  {
    key: 'pic-5',
    uri: '/assets/images/characters/character-5.svg',
    name: 'Character 5'
  },
  {
    key: 'pic-6',
    uri: '/assets/images/characters/character-6.svg',
    name: 'Character 6'
  },
  {
    key: 'pic-7',
    uri: '/assets/images/characters/character-7.svg',
    name: 'Character 7'
  },
  {
    key: 'pic-8',
    uri: '/assets/images/characters/character-8.svg',
    name: 'Character 8'
  },
  {
    key: 'pic-9',
    uri: '/assets/images/characters/character-9.svg',
    name: 'Character 9'
  },
  {
    key: 'pic-10',
    uri: '/assets/images/characters/character-10.svg',
    name: 'Character 10'
  },
  {
    key: 'pic-11',
    uri: '/assets/images/characters/character-11.svg',
    name: 'Character 11'
  },
  {
    key: 'pic-12',
    uri: '/assets/images/characters/character-12.svg',
    name: 'Character 12'
  },
  {
    key: 'pic-13',
    uri: '/assets/images/characters/character-13.svg',
    name: 'Character 13'
  },
  {
    key: 'pic-14',
    uri: '/assets/images/characters/character-14.svg',
    name: 'Character 14'
  },
  {
    key: 'pic-15',
    uri: '/assets/images/characters/character-15.svg',
    name: 'Character 15'
  },
  {
    key: 'pic-16',
    uri: '/assets/images/characters/character-16.svg',
    name: 'Character 16'
  },
  {
    key: 'pic-17',
    uri: '/assets/images/characters/character-17.svg',
    name: 'Character 17'
  },
  {
    key: 'pic-18',
    uri: '/assets/images/characters/character-18.svg',
    name: 'Character 18'
  },
  {
    key: 'pic-19',
    uri: '/assets/images/characters/character-19.svg',
    name: 'Character 19'
  },
  {
    key: 'pic-20',
    uri: '/assets/images/characters/character-20.svg',
    name: 'Character 20'
  },
  {
    key: 'pic-21',
    uri: '/assets/images/characters/character-21.svg',
    name: 'Character 21'
  },
  {
    key: 'pic-22',
    uri: '/assets/images/characters/character-22.svg',
    name: 'Character 22'
  },
  {
    key: 'pic-23',
    uri: '/assets/images/characters/character-23.svg',
    name: 'Character 23'
  },
  {
    key: 'pic-24',
    uri: '/assets/images/characters/character-24.svg',
    name: 'Character 24'
  },
  {
    key: 'pic-25',
    uri: '/assets/images/characters/character-25.svg',
    name: 'Character 25'
  }
];