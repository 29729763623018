import axios from 'axios';

export default class BaseApi {
  constructor () {
    this.api_token = null;
    this.client = null;
    this.origin = process.env.REACT_APP_BACKEND_URL;
    this.service_uri = {
      get: '',
      create: '',
      update: ''
    }
  }

  request () {
    this.api_token = window.token || '';
    let headers = {
      Accept: "application/json"
    };
    if (this.api_token) {
      headers.Authorization = `x-access-token ${this.api_token}`;
    }
    this.client = axios.create({ baseURL: this.api_url, timeout: 31000, headers: headers });
    return this.client;
  }

  serializerOjectToQueryString (obj, prefix) {
    if (obj && typeof obj === 'object') {
      const serializedArr = []
      let key = {}

      for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const k = prefix ? prefix + '[' + key + ']' : key
          const value = obj[key] || null
          serializedArr.push((value !== null && typeof value === 'object')
            ? this.serializerOjectToQueryString(value, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(value))
        }
      }
      return serializedArr.join('&')
    }
  }

  objectToQueryString (obj) {
    if (obj && typeof obj === 'object') {
      const result = this.serializerOjectToQueryString(obj)
      return `?${result}`
    } else {
      return ''
    }
  }

  async getByParameters (data) {
    try {
      if (!data) {
        return null
      }

      const parameters = this.objectToQueryString(data)

      const result = await this.request()
        .get(`${this.origin}${this.service_uri.get}${parameters}`,
          {
            headers: {
              'x-access-token': this.api_token
            }
          })

      return result.data
    } catch (error) {
      console.error(error)
      return error.body
    }
  }

  async update (data) {
    try {
      if (!data) {
        return null
      }

      const result = await this.request()
        .patch(`${this.origin}${this.service_uri.update}`,
          data,
          {
            headers: {
              'x-access-token': this.api_token
            }
          })

      return result.data
    } catch (error) {
      console.error(error)
      return error.body
    }
  }

  async create (data) {
    try {
      if (!data) {
        return null
      }

      const result = await this.request()
        .post(`${this.origin}${this.service_uri.create}`,
          data,
          {
            headers: {
              'x-access-token': this.api_token
            }
          })

      return result.data
    } catch (error) {
      console.error(error)
      return error.body
    }
  }

  async post (data, uri) {
    try {
      if (!data) {
        return null
      }

      const result = await this.request()
        .post(`${this.origin}${uri}`,
          data,
          {
            headers: {
              'x-access-token': this.api_token
            }
          })

      return result.data
    } catch (error) {
      console.error(error)
      return error.body
    }
  }

  async get (data, uri) {
    try {
      if (!data) {
        return null
      }

      const parameters = this.objectToQueryString(data)

      const result = await this.request()
        .get(`${this.origin}${uri}${parameters}`,
          {
            headers: {
              'x-access-token': this.api_token
            }
          })

      return result.data
    } catch (error) {
      console.error(error)
      return error.body
    }
  }
}