import React from "react";

function FooterAdmin() {
    return (
        <footer>
            
        </footer>
    );
}

export default FooterAdmin;