import React from "react";

function ConfigurationAdmin() {
    return (
        <div data-simplebar className="h-100">

            <div className="rightbar-title bg-black">
                <a href="#" className="right-bar-toggle float-end">
                    <i className="mdi mdi-close"></i>
                </a>
                <h4 className="font-16 m-0 text-white">Configuración</h4>
            </div>


            <div className="tab-content pt-0">

                <div className="tab-pane active" id="settings-tab" role="tabpanel">

                    <div className="p-3">
                        <h6 className="fw-medium font-14 mt-0 mb-2 pb-1">Color Scheme</h6>
                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="layout-color" value="light"
                                id="light-mode-check" defaultChecked={true} />
                            <label className="form-check-label" htmlFor="light-mode-check">Light Mode</label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="layout-color" value="dark"
                                id="dark-mode-check" />
                            <label className="form-check-label" htmlFor="dark-mode-check">Dark Mode</label>
                        </div>


                        <h6 className="fw-medium font-14 mt-4 mb-2 pb-1">Width</h6>
                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="layout-size" value="fluid" id="fluid" defaultChecked={true} />
                            <label className="form-check-label" htmlFor="fluid-check">Fluid</label>
                        </div>
                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="layout-size" value="boxed" id="boxed" />
                            <label className="form-check-label" htmlFor="boxed-check">Boxed</label>
                        </div>


                        <h6 className="fw-medium font-14 mt-4 mb-2 pb-1">Menus (Leftsidebar and Topbar) Positon</h6>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-position" value="fixed" id="fixed-check"
                                defaultChecked={true} />
                            <label className="form-check-label" htmlFor="fixed-check">Fixed</label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-position" value="scrollable"
                                id="scrollable-check" />
                            <label className="form-check-label" htmlFor="scrollable-check">Scrollable</label>
                        </div>


                        <h6 className="fw-medium font-14 mt-4 mb-2 pb-1">Left Sidebar Color</h6>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-color" value="light" id="light" />
                            <label className="form-check-label" htmlFor="light-check">Light</label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-color" value="dark" id="dark" defaultChecked={true} />
                            <label className="form-check-label" htmlFor="dark-check">Dark</label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-color" value="brand" id="brand" />
                            <label className="form-check-label" htmlFor="brand-check">Brand</label>
                        </div>

                        <div className="form-check form-switch mb-3">
                            <input type="checkbox" className="form-check-input" name="leftbar-color" value="gradient" id="gradient" />
                            <label className="form-check-label" htmlFor="gradient-check">Gradient</label>
                        </div>


                        <h6 className="fw-medium font-14 mt-4 mb-2 pb-1">Left Sidebar Size</h6>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-size" value="default"
                                id="default-size-check" defaultChecked={true} />
                            <label className="form-check-label" htmlFor="default-size-check">Default</label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-size" value="condensed"
                                id="condensed-check" />
                            <label className="form-check-label" htmlFor="condensed-check">Condensed <small>(Extra Small size)</small></label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="leftbar-size" value="compact"
                                id="compact-check" />
                            <label className="form-check-label" htmlFor="compact-check">Compact <small>(Small size)</small></label>
                        </div>


                        <h6 className="fw-medium font-14 mt-4 mb-2 pb-1">Sidebar User Info</h6>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="sidebar-user" value="true" id="sidebaruser-check" />
                            <label className="form-check-label" htmlFor="sidebaruser-check">Enable</label>
                        </div>



                        <h6 className="fw-medium font-14 mt-4 mb-2 pb-1">Topbar</h6>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="topbar-color" value="dark" id="darktopbar-check"
                                defaultChecked={true} />
                            <label className="form-check-label" htmlFor="darktopbar-check">Dark</label>
                        </div>

                        <div className="form-check form-switch mb-1">
                            <input type="checkbox" className="form-check-input" name="topbar-color" value="light" id="lighttopbar-check" />
                            <label className="form-check-label" htmlFor="lighttopbar-check">Light</label>
                        </div>

                        <div className="d-grid mt-4">
                            <button className="btn btn-primary" id="resetBtn">Reset to Default</button>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default ConfigurationAdmin;