import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SidebarBusiness from "../components/sidebar/SidebarBusiness";
import NavbarBusiness from "../components/navbar/NavbarBusiness";
import FooterBusiness from "../components/footer/FooterBusiness";
import ConfigurationBusiness from "../components/configuration/ConfigurationBusiness";
import { useAuth } from "../hooks/useAuth.hook";

function SetUI () {
  useEffect(() => {
    const element = document.querySelector('body');
    element.classList.remove('authentication-bg')
  }, []);
}

const LayoutBusiness = () => {
  const [isAdmin] = useState(false);
  const [pageName, setPageName] = useState('');

  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  SetUI();

  return (
    <>
      <div id="wrapper">
        <NavbarBusiness pageName={pageName} />
        <SidebarBusiness />
        <div className="content-page">
          <div className="content">
            {/* Start Content */}
            <Outlet context={{ isAdmin, setPageName }} />
            {/* End Content */}
          </div>

          <FooterBusiness />
        </div>
      </div >

      <div className="right-bar">
        <ConfigurationBusiness />
      </div>
    </>
  );
};

export default LayoutBusiness;