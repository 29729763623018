import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth.hook';
import { useExternalScript } from '../../hooks/useExternalScript.hook';
import { UserService } from '../../services/index';

async function getEntity (payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result;
}

function removeMyUser (arr, identity) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].dni === identity) {
      arr.splice(i, 1);
      i--;
    }
  }
  return arr;
}

function DashboardAdmin () {
  const { setPageName } = useOutletContext();
  const { user } = useAuth();

  const [pinnedUserList, setPinnedUserList] = useState([]);

  const knobScript = "/assets/libs/jquery-knob/jquery.knob.min.js";
  const dashboardScript = "/assets/js/pages/dashboard.admin.js";

  useExternalScript(knobScript);
  useExternalScript(dashboardScript);

  useEffect(() => {
    setPageName('Dashboard');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dashboardPinnedUserList = await getEntity({
        pinnedDashboard: true
      }, UserService);

      console.log(dashboardPinnedUserList)

      const segregatedUserList = removeMyUser(dashboardPinnedUserList, user.identity)

      setPinnedUserList(segregatedUserList)
    }

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <section className="container-fluid">
        {/* Insights */}
        <section className="row">
          <div className="col-12">
            <h4 className="header-title mb-1">Insights</h4>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-4">Servicios</h4>

                <div className="widget-chart-1">
                  <div className="widget-chart-box-1 float-start" dir="ltr">
                    <input data-plugin="knob" data-width="70" data-height="70" data-fgcolor="#f05050 "
                      data-bgcolor="#F9B9B9" value={58} readOnly={true}
                      data-skin="tron" data-angleoffset="180" data-readonly="true"
                      data-thickness=".15" />
                  </div>

                  <div className="widget-detail-1 text-end">
                    <h2 className="fw-normal pt-2 mb-1"> 256 </h2>
                    <p className="text-muted mb-1">Total hoy</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-3">Cobros</h4>

                <div className="widget-box-2">
                  <div className="widget-detail-2 text-end">
                    <span className="badge bg-success rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                    <h2 className="fw-normal mb-1"> 8451 </h2>
                    <p className="text-muted mb-3">Total hoy</p>
                  </div>
                  <div className="progress progress-bar-alt-success progress-sm">
                    <div className="progress-bar bg-success w-75" role="progressbar"
                      aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">
                      <span className="visually-hidden">77% Complete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-4">Clientes</h4>

                <div className="widget-chart-1">
                  <div className="widget-chart-box-1 float-start" dir="ltr">
                    <input data-plugin="knob" data-width="70" data-height="70" data-fgcolor="#ffbd4a"
                      data-bgcolor="#FFE6BA" value={80} readOnly={true}
                      data-skin="tron" data-angleoffset="180" data-readonly="true"
                      data-thickness=".15" />
                  </div>
                  <div className="widget-detail-1 text-end">
                    <h2 className="fw-normal pt-2 mb-1"> 4569 </h2>
                    <p className="text-muted mb-1">Activos hoy</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-3">Calificación</h4>

                <div className="widget-box-2">
                  <div className="widget-detail-2 text-end">
                    <span className="badge bg-pink rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                    <h2 className="fw-normal mb-1"> 158 </h2>
                    <p className="text-muted mb-3">Total hoy</p>
                  </div>
                  <div className="progress progress-bar-alt-pink progress-sm">
                    <div className="progress-bar bg-pink w-75" role="progressbar"
                      aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">
                      <span className="visually-hidden">77% Complete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        {/* People */}
        <section className="row">
          <div className="col-12">
            <h4 className="header-title mb-1">Equipo</h4>
          </div>

          {pinnedUserList.map(_user => (
            <div className="col-xl-3 col-md-6" key={_user.id}>
              <article className="card">
                <div className="card-body widget-user">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 avatar-lg me-3">
                      <img src={_user.profile_pic_uri || '/assets/images/characters/character-unknown.svg'} className="img-fluid rounded-circle border" alt="user" />
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="mt-0 mb-1 text-truncate">{_user.firstname + ' ' + _user.lastname}</h5>
                      <p className="text-muted mb-2 font-13 text-truncate">{_user.email}</p>
                      <small className="text-black-50"><b>{_user.position_name}</b></small>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}

        </section>

        {/* Quick links */}
        <section className="row">
          <div className="col-12">
            <h4 className="header-title mb-1">Quick links</h4>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/insights.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/insight" className="btn btn-bordered-primary">Insights</a>
              </div>
            </article>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/operations.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/operations" className="btn btn-bordered-primary">Operaciones</a>
              </div>
            </article>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/driver.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/driver" className="btn btn-bordered-primary">Conductores</a>
              </div>
            </article>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/vehicles.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/vehicle" className="btn btn-bordered-primary">Vehiculos</a>
              </div>
            </article>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/clients.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/client" className="btn btn-bordered-primary">Clientes</a>
              </div>
            </article>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/business.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/business" className="btn btn-bordered-primary">Negocios</a>
              </div>
            </article>
          </div>

          <div className="col-md-4 col-xl-3">
            <article className="card shadow">
              <img className="card-img-top img-fluid" src="/assets/images/dashboard/users.jpg" alt="Card image cap" />
              <div className="card-body text-center">
                <a href="/admin/user" className="btn btn-bordered-primary">Usuarios</a>
              </div>
            </article>
          </div>
        </section>
      </section>
    </>

  );
}

export default DashboardAdmin;