import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import OffCanvasData from '../../components/utilities/OffCanvasData';
import { VehicleService } from '../../services/index';

async function getEntities (payload) {
  const entityService = new VehicleService();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result
}

function VehicleList () {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Vehiculos');
  }, []);

  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const entities = await getEntities({
        all: true
      });

      setEntities(entities);
      console.log(entities);
    }

    fetchData().catch(console.error);
  }, []);

  return (
    <section className="container-fluid">
      <div className="row">
        <div className="col-12">
          <article className="card">
            <section className="card-body">
              <header>
                <section className="dropdown float-end">
                  <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="/admin/vehicle/create" className="dropdown-item"><i className="mdi mdi-plus me-1"></i> Crear nuevo</a>
                  </div>
                </section>
                <h4 className="mt-0 header-title">Centro de control</h4>
                <p className="text-muted font-14 mb-3">
                  Los vehículos son todos aquellos que están siendo soportados en tu plataforma.
                </p>
              </header>

              <section className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Placa</th>
                      <th>Conductor</th>
                      <th>Dueño</th>
                      <th>Marca</th>
                      <th>Pasajeros</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities.map(entity => (
                      <tr key={entity.id}>
                        <th scope="row">{entity.plate}</th>
                        <td><a href={`/admin/account/overview?id=${entity.assigned_driver_id}`} target="_blank" className="btn-link">{entity.assigned_driver_id}</a> </td>
                        <td><a href={`/admin/account/overview?id=${entity.owner_driver_id}`} target="_blank" className="btn-link">{entity.owner_driver_id}</a></td>
                        <td>{entity.brand_name}</td>
                        <td>{entity.passengers}</td>
                        <td>
                          <OffCanvasData className="btn btn-purple waves-effect waves-light btn-xs"
                            icon="mdi mdi-open-in-new"
                            id={entity.id}
                            title={entity.name}
                            content="Detalles"
                            entity={entity} />
                          <a href={`/admin/vehicle/update?id=${entity.id}`} className="btn btn-purple waves-effect waves-light btn-xs mx-1 my-1">
                            <i className="mdi mdi-square-edit-outline"></i> Editar
                          </a>
                          <a href={`/admin/vehicle/delete?id=${entity.id}`} className="btn btn-danger waves-effect waves-light btn-xs">
                            <i className="mdi mdi-delete"></i> Eliminar
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </section>
          </article>
        </div>
      </div>
    </section>
  )
}

export default VehicleList;