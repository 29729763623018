import React, { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth.hook';
import { SecurityService } from '../../services';

function SetUI () {
  useEffect(() => {
    const element = document.querySelector('body');
    element.classList.add('authentication-bg')
  });
}

async function login (payload) {
  const entityService = new SecurityService();
  const entityResponse = await entityService.login(payload);
  let entity = {}

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  console.log(entityResponse);
  entity = entityResponse.result;

  return entity.payload
}

function AuthLogin () {
  const authProvider = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authPayload = await login({
      identity: email,
      password
    });

    authProvider.login(authPayload);
  }

  SetUI();

  return (
    <div className="col-md-8 col-lg-6 col-xl-4">
      <div className="text-center">
        <a href="/">
          <img src="/assets/images/logo-dark.svg" alt="" height="22" className="mx-auto" />
        </a>
        <p className="text-muted mt-2 mb-4">La nueva forma de pedir servicios de transporte</p>

      </div>
      <div className="card">
        <div className="card-body p-4">

          <div className="text-center mb-4">
            <h4 className="text-uppercase mt-0">Sign In</h4>
          </div>

          <form onSubmit={e => { handleSubmit(e) }} autoComplete="off">
            <div className="mb-3">
              <label htmlFor="emailaddress" className="form-label">Email</label>
              <input className="form-control" type="email" id="emailaddress" required="" placeholder="Ingresa tu email"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">Contraseña</label>
              <input className="form-control" type="password" required="" id="password" placeholder="Ingresa tu contraseña"
                value={password}
                onChange={e => setPassword(e.target.value)} />
            </div>

            <div className="mb-3">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="checkbox-signin" defaultChecked={true} />
                <label className="form-check-label" htmlFor="checkbox-signin">Recordarme</label>
              </div>
            </div>

            <div className="mb-3 d-grid text-center">
              <button type="submit" className="btn btn-primary"> Ingresar </button>
            </div>
          </form>

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 text-center">
          <p> <a href="pages-recoverpw.html" className="text-muted ms-1"><i className="fa fa-lock me-1"></i>Olvidaste la clave?</a></p>
        </div>
      </div>

    </div>
  );
}

export default AuthLogin;