import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SidebarAdmin from "../components/sidebar/SidebarAdmin";
import NavbarAdmin from "../components/navbar/NavbarAdmin";
import FooterAdmin from "../components/footer/FooterAdmin";
import ConfigurationAdmin from "../components/configuration/ConfigurationAdmin";
import { useAuth } from "../hooks/useAuth.hook";

function SetUI () {
  useEffect(() => {
    const element = document.querySelector('body');
    element.classList.remove('authentication-bg')
  }, []);
}

const LayoutAdmin = () => {
  const [isAdmin] = useState(true);
  const [pageName, setPageName] = useState('');

  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  SetUI();

  return (
    <>
      <div id="wrapper">
        <NavbarAdmin pageName={pageName} />
        <SidebarAdmin />
        <div className="content-page">
          <div className="content">
            {/* Start Content */}
            <Outlet context={{ isAdmin, setPageName }} />
            {/* End Content */}
          </div>

          <FooterAdmin />
        </div>
      </div >

      <div className="right-bar">
        <ConfigurationAdmin />
      </div>
    </>
  );
};

export default LayoutAdmin;