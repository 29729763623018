import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BusinessService } from '../../services/index';

async function createEntity (payload, isAdmin) {
  const entityService = new BusinessService();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  if (!entityResponse || !entityResponse.result) {
    return;
  }

  console.log(entityResponse);

  if (isAdmin) {
    window.location.assign('/admin/business');
  }
}

function BusinessCreate () {
  const { isAdmin, setPageName} = useOutletContext();

  useEffect(() => {
    setPageName('Negocios');
  }, []);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [image, setImage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    createEntity({
      name,
      address,
      city,
      phone,
      country,
      image
    }, isAdmin);
  }

  return (
    <section className="container-fluid">
      <div className="row">
        <section className="col-12">
          <article className="card">
            <div className="card-body">
              <h4 className="header-title">Crear nuevo negocio</h4>
              <p className="text-muted font-13">Aquí podrás crear nuevos negocios con la siguiente información.</p>

              <form onSubmit={e => { handleSubmit(e) }} autoComplete="off">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="input-name" className="form-label">Razón social</label>
                    <input type="text" className="form-control" id="input-name" placeholder="Ingresa aquí el nombre del negocio"
                      value={name}
                      onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="input-phone" className="form-label">Teléfono</label>
                    <input type="tel" className="form-control" id="input-phone" placeholder="Ingresa aquí el teléfono de contacto"
                      value={phone}
                      onChange={e => setPhone(e.target.value)} />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="input-address" className="form-label">Address</label>
                    <input type="text" className="form-control" id="input-address" placeholder="Ingresa aquí la dirección completa"
                      value={address}
                      onChange={e => setAddress(e.target.value)} />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="input-city" className="form-label">Ciudad</label>
                    <input type="text" className="form-control" id="input-city" placeholder="Ingresa la ciudad de la dirección"
                      value={city}
                      onChange={e => setCity(e.target.value)} />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="input-country" className="form-label">País</label>
                    <select id="input-country" className="form-select"
                      value={country}
                      onChange={e => setCountry(e.target.value)}>
                      <option>Choose</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label htmlFor="input-photo" className="form-label">Foto</label>
                    <input type="text" className="form-control" id="input-photo" placeholder="https://..."
                      value={image}
                      onChange={e => setImage(e.target.value)} />
                  </div>
                </div>

                <div className="d-flex">
                  <button type="submit" className="btn btn-primary waves-effect waves-light me-1">Guardar</button>
                  <button type="submit" className="btn btn-danger waves-effect waves-light ms-1">Cancelar</button>
                </div>

              </form>
            </div>
          </article>
        </section>
      </div>
    </section>
  );
}

export default BusinessCreate;