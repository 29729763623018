import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DriverService } from '../../services/index';

async function createEntity (payload, isAdmin) {
  const entityService = new DriverService();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  if (!entityResponse || !entityResponse.result) {
    return;
  }

  console.log(entityResponse);

  if (isAdmin) {
    window.location.assign('/admin/driver');
  } else {
    window.location.assign('/business/driver');
  }
}

function DriverCreate () {
  const { isAdmin, setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Conductores');
  }, []);

  const [dni, setDni] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [score, setScore] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    createEntity({
      dni,
      email,
      phone,
      firstname,
      lastname,
      score,
      payment_details: paymentDetails
    }, isAdmin);
  }

  return (
    <section className="container-fluid">
      <div className="row">
        <section className="col-12">
          <article className="card">
            <div className="card-body">
              <h4 className="header-title">Crear conductor nuevo</h4>
              <p className="text-muted font-13">Aquí podrás crear conductores nuevos con la siguiente información.</p>

              <form onSubmit={e => { handleSubmit(e) }} autoComplete="off">
                <div className="row">
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="text" className="form-control" id="input-firstname" placeholder="Ingresa el nombre"
                      value={firstname}
                      onChange={e => setFirstname(e.target.value)} />
                    <label htmlFor="input-firstname" className="form-label">Nombres</label>
                  </div>
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="text" className="form-control" id="input-lastname" placeholder="Ingresa el apellido"
                      value={lastname}
                      onChange={e => setLastname(e.target.value)} />
                    <label htmlFor="input-lastname" className="form-label">Apellidos</label>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6 form-floating">
                    <input type="text" className="form-control" id="input-dni" placeholder="Ingresa la identificación nacional"
                      value={dni}
                      onChange={e => setDni(e.target.value)} />
                    <label htmlFor="input-dni" className="form-label">Identificación</label>
                  </div>

                  <div className="mb-3 col-md-6 form-floating">
                    <input type="tel" className="form-control" id="input-phone" placeholder="Ingresa el teléfono de WhatsApp"
                      value={phone}
                      onChange={e => setPhone(e.target.value)} />
                    <label htmlFor="input-phone" className="form-label">Teléfono</label>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6 form-floating">
                    <input type="email" className="form-control" id="input-email" placeholder="Ingresa el email"
                      value={email}
                      onChange={e => setEmail(e.target.value)} />
                    <label htmlFor="input-email" className="form-label">Email</label>
                  </div>

                  <div className="mb-3 col-md-6 form-floating">
                    <input type="number" className="form-control" id="input-score" placeholder="Ingresa un puntaje inicial"
                      value={score}
                      onChange={e => setScore(e.target.value)} />
                    <label htmlFor="input-score" className="form-label">Puntaje</label>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-12 form-floating">
                    <textarea className="form-control" id="input-payment_details" placeholder="Ej: Nequi - 3103494807"
                      value={paymentDetails}
                      onChange={e => setPaymentDetails(e.target.value)} />
                    <label htmlFor="input-payment_details" className="form-label">Detalle de pago</label>
                  </div>
                </div>

                <div className="d-flex">
                  <button type="submit" className="btn btn-primary waves-effect waves-light me-1">Guardar</button>
                  <button type="button" className="btn btn-danger waves-effect waves-light ms-1">Cancelar</button>
                </div>

              </form>

            </div>
          </article>
        </section>
      </div>
    </section>
  );
}

export default DriverCreate;