import BaseApi from '../base/apiBase.service'

export default class VehicleService extends BaseApi {
  constructor (args) {
    super(args)

    this.service_uri = {
      get: '/drive/vehicle',
      create: '/drive/vehicle/',
      update: '/drive/vehicle/'
    }
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }
}