import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth.hook';
import { PROFILE_PICTURES } from '../../constants/profilePictures';
import { UserService } from '../../services/index';

async function getEntity (payload) {
  const entityService = new UserService();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result;
}

async function updateEntity (payload, isAdmin) {
  const entityService = new UserService();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  if (!entityResponse || !entityResponse.result) {
    return;
  }

  console.log(entityResponse);

  if (isAdmin) {
    window.location.assign('/admin/account/overview');
  } else {
    window.location.assign('/business/account/overview');
  }
}

function AccountProfile () {
  const { isAdmin, setPageName } = useOutletContext();
  const { user } = useAuth();

  const profilePictures = PROFILE_PICTURES;

  const [id, setId] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dni, setDni] = useState('');
  const [phone, setPhone] = useState('');
  const [profilePicUri, setProfilePicUri] = useState('');
  const [positionName, setPositionName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    updateEntity({
      identity: id,
      id,
      firstname,
      lastname,
      email,
      password,
      dni,
      phone,
      profile_pic_uri: profilePicUri,
      position_name: positionName
    }, isAdmin);
  }

  useEffect(() => {
    const fetchData = async () => {
      const entity = await getEntity({
        identity: user.identity
      }, UserService);

      setId(entity.id);
      setFirstname(entity.firstname);
      setLastname(entity.lastname);
      setEmail(entity.email);
      setPassword('');
      setDni(entity.dni);
      setPhone(entity.phone);
      setProfilePicUri(entity.profile_pic_uri);
      setPositionName(entity.position_name);
      console.log(entity);
    }

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setPageName('Perfil');
  }, []);

  return (
    <section className="container-fluid">
      <div className="row">
        <section className="col-12">
          <article className="card">
            <div className="card-body">
              <h4 className="header-title">Editar perfil</h4>
              <p className="text-muted font-13">Aquí podrás editar tu perfil con la siguiente información.</p>

              <form onSubmit={e => { handleSubmit(e) }} autoComplete="off">
                <div className="row">
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="text" className="form-control" id="input-firstname" placeholder="Escribe aquí los nombres"
                      value={firstname}
                      onChange={e => setFirstname(e.target.value)} />
                    <label htmlFor="input-firstname" className="form-label">Nombres</label>
                  </div>
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="text" className="form-control" id="input-lastname" placeholder="Escribe aquí los apellidos"
                      value={lastname}
                      onChange={e => setLastname(e.target.value)} />
                    <label htmlFor="input-lastname" className="form-label">Apellidos</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="email" className="form-control" id="input-email" placeholder="Escribe aquí el email"
                      value={email}
                      onChange={e => setEmail(e.target.value)} />
                    <label htmlFor="input-email" className="form-label">Email</label>
                  </div>
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="password" className="form-control" id="input-password" placeholder="Escribe aquí la contraseña"
                      value={password}
                      onChange={e => setPassword(e.target.value)} />
                    <label htmlFor="input-password" className="form-label">Contraseña</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="text" className="form-control" id="input-dni" placeholder="Escribe aquí la identificación nacional"
                      value={dni}
                      onChange={e => setDni(e.target.value)} />
                    <label htmlFor="input-dni" className="form-label">Identificación</label>
                  </div>
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="tel" className="form-control" id="input-phone" placeholder="Escribe aquí el teléfono de contacto"
                      value={phone}
                      onChange={e => setPhone(e.target.value)} />
                    <label htmlFor="input-phone" className="form-label">Teléfono</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 form-floating">
                    <select id="input-profile_pic_uri" className="form-select"
                      value={profilePicUri}
                      onChange={e => setProfilePicUri(e.target.value)}>
                      <option>Selecciona...</option>
                      {profilePictures.map((picture) => (
                        <option key={picture.key} value={picture.uri}>{picture.name}</option>
                      ))}
                    </select>
                    <label htmlFor="input-profile_pic_uri" className="form-label">Foto de perfil</label>
                  </div>
                  <div className="col-md-6 mb-3 form-floating">
                    <input type="text" className="form-control" id="input-position_name" placeholder="Escribe aquí el cargo"
                      value={positionName}
                      onChange={e => setPositionName(e.target.value)} />
                    <label htmlFor="input-position_name" className="form-label">Cargo</label>
                  </div>
                </div>

                <div className="d-flex">
                  <button type="submit" className="btn btn-primary waves-effect waves-light me-1">Guardar</button>
                  <button type="button" className="btn btn-danger waves-effect waves-light ms-1">Cancelar</button>
                </div>

              </form>

            </div>
          </article>
        </section>
      </div>
    </section>
  );
}

export default AccountProfile;