import BaseApi from '../base/apiBase.service'

export default class UserService extends BaseApi {
  constructor (args) {
    super(args)

    this.service_uri = {
      get: '/identity/user',
      create: '/identity/user/',
      update: '/identity/user/'
    }
  }

  async getByParameters (data) {
    return super.getByParameters(data);
  }

  async update (data) {
    return super.update(data);
  }

  async create (data) {
    return super.create(data);
  }
}