import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { VehicleService } from '../../services/index';

async function getEntity (payload) {
  const entityService = new VehicleService();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result;
}

async function updateEntity (payload, isAdmin) {
  const entityService = new VehicleService();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  if (!entityResponse || !entityResponse.result) {
    return;
  }

  console.log(entityResponse);

  if (isAdmin) {
    window.location.assign('/admin/vehicle');
  } else {
    window.location.assign('/business/vehicle');
  }
}

function VehicleUpdate () {
  const { isAdmin,setPageName } = useOutletContext();
  const params = Object.fromEntries(new URLSearchParams(window.location.search));

  useEffect(() => {
    setPageName('Vehiculos');
  }, []);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [plate, setPlate] = useState('');
  const [passengers, setPassengers] = useState(0);
  const [brandName, setBrandName] = useState('');
  const [ownerDriverId, setOwnerDriverId] = useState();
  const [assignedDriverId, setAssignedDriverId] = useState();
  const [photoUri, setPhotoUri] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    updateEntity({
      id,
      name,
      plate,
      passengers,
      brand_name: brandName,
      owner_driver_id: ownerDriverId,
      assigned_driver_id: assignedDriverId,
      photo_uri: photoUri
    }, isAdmin);
  }

  useEffect(() => {
    const fetchData = async () => {
      const entity = await getEntity({
        id: params.id
      });

      setId(entity.id);
      setName(entity.name);
      setPlate(entity.plate);
      setPassengers(entity.passengers);
      setBrandName(entity.brand_name);
      setOwnerDriverId(entity.owner_driver_id);
      setAssignedDriverId(entity.assigned_driver_id);
      setPhotoUri(entity.photo_uri);
      console.log(entity);
    }

    fetchData().catch(console.error);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title">Editar vehículo</h4>
              <p className="text-muted font-13">Aquí podrás crear nuevos vehículos con la siguiente información.</p>

              <form onSubmit={e => { handleSubmit(e) }} autoComplete="off">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="input-name" className="form-label">Nombre</label>
                    <input type="text" className="form-control" id="input-name" placeholder="Escribe el nombre del vehículo aquí"
                      value={name}
                      onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="input-plate" className="form-label">Placa</label>
                    <input type="text" className="form-control" id="input-plate" placeholder="Escribe la placa aquí"
                      value={plate}
                      onChange={e => setPlate(e.target.value)} />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="input-passengers" className="form-label">Pasajeros</label>
                    <input type="number" className="form-control" id="input-passengers" placeholder="¿Qué capacidad tiene el vehículo?"
                      value={passengers}
                      onChange={e => setPassengers(e.target.value)} />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="input-brand_name" className="form-label">Marca</label>
                    <input type="text" className="form-control" id="input-brand_name" placeholder="Yamaha, Bajaj, etc"
                      value={brandName}
                      onChange={e => setBrandName(e.target.value)} />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="input-owner_driver_id" className="form-label">Dueño</label>
                    <select id="input-owner_driver_id" className="form-select"
                      value={ownerDriverId}
                      onChange={e => setOwnerDriverId(e.target.value)} >
                      <option>Choose</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="input-assigned_driver_id" className="form-label">Conductor</label>
                    <select id="input-assigned_driver_id" className="form-select"
                      value={assignedDriverId}
                      onChange={e => setAssignedDriverId(e.target.value)} >
                      <option>Choose</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label htmlFor="input-photo_uri" className="form-label">Foto</label>
                    <input type="text" className="form-control" id="input-photo_uri" placeholder="https://..."
                      value={photoUri}
                      onChange={e => setPhotoUri(e.target.value)} />
                  </div>
                </div>

                <div className="d-flex">
                  <button type="submit" className="btn btn-primary waves-effect waves-light me-1">Guardar</button>
                  <button type="button" className="btn btn-danger waves-effect waves-light ms-1">Cancelar</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleUpdate;