import React from "react";
import { useAuth } from "../../hooks/useAuth.hook";

function NavbarBusiness (props) {
  const { user } = useAuth();

  return (
    <div className="navbar-custom">
      <ul className="list-unstyled topnav-menu float-end mb-0">

        <li className="dropdown d-inline-block d-lg-none">
          <a className="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <i className="fe-search noti-icon"></i>
          </a>
          <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
            <form className="p-3">
              <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
            </form>
          </div>
        </li>

        <li className="dropdown notification-list topbar-dropdown">
          <a className="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <i className="fe-bell noti-icon"></i>
            <span className="badge bg-danger rounded-circle noti-icon-badge">9</span>
          </a>
          <div className="dropdown-menu dropdown-menu-end dropdown-lg">

            {/* <!-- item--> */}
            <div className="dropdown-item noti-title">
              <h5 className="m-0">
                <span className="float-end">
                  <a href="" className="text-dark">
                    <small>Clear All</small>
                  </a>
                </span>Notification
              </h5>
            </div>

            <div className="noti-scroll" data-simplebar>

              {/* <!-- item--> */}
              <a href="#" className="dropdown-item notify-item active">
                <div className="notify-icon">
                  <img src="/assets/images/users/user-1.jpg" className="img-fluid rounded-circle" alt="" /> </div>
                <p className="notify-details">Cristina Pride</p>
                <p className="text-muted mb-0 user-msg">
                  <small>Hi, How are you? What about our next meeting</small>
                </p>
              </a>

              {/* <!-- item--> */}
              <a href="#" className="dropdown-item notify-item">
                <div className="notify-icon bg-primary">
                  <i className="mdi mdi-comment-account-outline"></i>
                </div>
                <p className="notify-details">Caleb Flakelar commented on Admin
                  <small className="text-muted">1 min ago</small>
                </p>
              </a>

              {/* <!-- item--> */}
              <a href="#" className="dropdown-item notify-item">
                <div className="notify-icon">
                  <img src="/assets/images/users/user-4.jpg" className="img-fluid rounded-circle" alt="" /> </div>
                <p className="notify-details">Karen Robinson</p>
                <p className="text-muted mb-0 user-msg">
                  <small>Wow ! this admin looks good and awesome design</small>
                </p>
              </a>

              {/* <!-- item--> */}
              <a href="#" className="dropdown-item notify-item">
                <div className="notify-icon bg-warning">
                  <i className="mdi mdi-account-plus"></i>
                </div>
                <p className="notify-details">New user registered.
                  <small className="text-muted">5 hours ago</small>
                </p>
              </a>

              {/* <!-- item--> */}
              <a href="#" className="dropdown-item notify-item">
                <div className="notify-icon bg-info">
                  <i className="mdi mdi-comment-account-outline"></i>
                </div>
                <p className="notify-details">Caleb Flakelar commented on Admin
                  <small className="text-muted">4 days ago</small>
                </p>
              </a>

              {/* <!-- item--> */}
              <a href="#" className="dropdown-item notify-item">
                <div className="notify-icon bg-secondary">
                  <i className="mdi mdi-heart"></i>
                </div>
                <p className="notify-details">Carlos Crouch liked
                  <b>Admin</b>
                  <small className="text-muted">13 days ago</small>
                </p>
              </a>
            </div>

            {/* <!-- All--> */}
            <a href="#" className="dropdown-item text-center text-primary notify-item notify-all">
              View all
              <i className="fe-arrow-right"></i>
            </a>

          </div>
        </li>

        <li className="dropdown notification-list topbar-dropdown">
          <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false" >
            <img src={user.profile_pic_uri || '/assets/images/characters/character-unknown.svg'} alt="user-image" className="rounded-circle bg-warning bg-moved-down" />
            <span className="pro-user-name ms-1">
              {user.firstname} <i className="mdi mdi-chevron-down"></i>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-end profile-dropdown ">

            {/* <!-- item--> */}
            <a href="/business/account/overview" className="dropdown-item notify-item">
              <i className="fe-user"></i>
              <span>Mi cuenta</span>
            </a>

            {/* <!-- item--> */}
            <a href="/auth/lock-screen" className="dropdown-item notify-item">
              <i className="fe-lock"></i>
              <span>Bloquear</span>
            </a>

            <div className="dropdown-divider"></div>

            {/* <!-- item--> */}
            <a href="/auth/logout" className="dropdown-item notify-item">
              <i className="fe-log-out"></i>
              <span>Salir</span>
            </a>

          </div>
        </li>

        <li className="dropdown notification-list">
          <a href="#" className="nav-link right-bar-toggle waves-effect waves-light">
            <i className="fe-settings noti-icon"></i>
          </a>
        </li>

      </ul>

      <div className="logo-box">
        <a href="/business" className="logo logo-light text-center">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-light.svg" alt="" height="16" />
          </span>
        </a>
        <a href="/business" className="logo logo-dark text-center">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-dark.svg" alt="" height="25" />
          </span>
        </a>
      </div>

      <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
        <li>
          <button className="button-menu-mobile disable-btn waves-effect">
            <i className="fe-menu"></i>
          </button>
        </li>

        <li>
          <h4 className="page-title-main">{props.pageName}</h4>
        </li>
      </ul>

      <div className="clearfix"></div>
    </div>
  );
}

export default NavbarBusiness;