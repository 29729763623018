import BaseApi from '../base/apiBase.service'

export default class InsightService extends BaseApi {
  constructor (args) {
    super(args)

    this.service_uri = {
      get: '/insight',
      create: '/insight/',
      update: '/insight/'
    }
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }
}