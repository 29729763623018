import React from "react";
import { Outlet } from "react-router-dom";
import SidebarAuth from "../components/sidebar/SidebarAuth";
import NavbarAuth from "../components/navbar/NavbarAuth";
import FooterAuth from "../components/footer/FooterAuth";

const LayoutAdmin = () => {
  return (
    <div className="account-pages mt-5 mb-5">
      <SidebarAuth />
      <NavbarAuth />
      <div className="container">
        <div className="row justify-content-center">
          {/* Start Content */}
          <Outlet />
          {/* End Content */}
        </div>
        <FooterAuth />
      </div>
    </div>
  );
};

export default LayoutAdmin;