import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import {
  AccountGettingStarted,
  AccountOverview,
  AccountProfile,
  AuthLogin,
  AuthLogout,
  AuthBridge,
  AuthLockscreen,
  BusinessCreate,
  BusinessList,
  BusinessUpdate,
  ClientCreate,
  ClientList,
  ClientUpdate,
  DriverCreate,
  DriverList,
  DriverUpdate,
  DashboardAdmin,
  DashboardBusiness,
  InsightHome,
  InsightFinance,
  InsightClients,
  InsightQuality,
  InsightService,
  InsightCondensed,
  MaintenanceGeneral,
  OperationsHome,
  OperationsQueue,
  UserCreate,
  UserList,
  UserUpdate,
  VehicleCreate,
  VehicleList,
  VehicleUpdate
} from './pages/index';

import {
  LayoutAdmin,
  LayoutAuth,
  LayoutMaintenance,
  LayoutBusiness
} from './layouts/index';
import { AuthProvider } from './hooks/useAuth.hook';

function App () {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LayoutAuth />}>
            <Route index element={<AuthBridge />} />
          </Route>
          <Route path="/auth" element={<LayoutAuth />}>
            <Route index element={<MaintenanceGeneral />} />
            <Route path="login" element={<AuthLogin />} />
            <Route path="logout" element={<AuthLogout />} />
            <Route path="lock-screen" element={<AuthLockscreen />} />
          </Route>
          <Route path="/admin" element={<LayoutAdmin />}>
            <Route index element={<DashboardAdmin />} />
            <Route path="account">
              <Route index path="overview" element={<AccountOverview />} />
              <Route element={<AccountGettingStarted />} />
              <Route path="profile" element={<AccountProfile />} />
            </Route>
            <Route path="business">
              <Route index element={<BusinessList />} />
              <Route path="create" element={<BusinessCreate />} />
              <Route path="update" element={<BusinessUpdate />} />
            </Route>
            <Route path="client" >
              <Route index element={<ClientList />} />
              <Route path="create" element={<ClientCreate />} />
              <Route path="update" element={<ClientUpdate />} />
            </Route>
            <Route path="driver" >
              <Route index element={<DriverList />} />
              <Route path="create" element={<DriverCreate />} />
              <Route path="update" element={<DriverUpdate />} />
            </Route>
            <Route path="insight" >
              <Route path="home" element={<InsightHome />} />
              <Route index element={<InsightCondensed />} />
              <Route path="clients" element={<InsightClients />} />
              <Route path="finance" element={<InsightFinance />} />
              <Route path="quality" element={<InsightQuality />} />
              <Route path="service" element={<InsightService />} />
            </Route>
            <Route path="operations" >
              <Route index element={<OperationsHome />} />
              <Route path="queue" element={<OperationsQueue />} />
            </Route>
            <Route path="user" >
              <Route index element={<UserList />} />
              <Route path="create" element={<UserCreate />} />
              <Route path="update" element={<UserUpdate />} />
            </Route>
            <Route path="vehicle" >
              <Route index element={<VehicleList />} />
              <Route path="create" element={<VehicleCreate />} />
              <Route path="update" element={<VehicleUpdate />} />
            </Route>
            <Route path="*" element={<MaintenanceGeneral />} />
          </Route>
          <Route path="/business" element={<LayoutBusiness />}>
            <Route index element={<DashboardBusiness />} />
            <Route path="account">
              <Route index path="overview" element={<AccountOverview />} />
              <Route path="getting-started" element={<AccountGettingStarted />} />
              <Route path="profile" element={<AccountProfile />} />
            </Route>
            <Route path="driver" >
              <Route index element={<DriverList />} />
              <Route path="create" element={<DriverCreate />} />
              <Route path="update" element={<DriverUpdate />} />
            </Route>
            <Route path="insight" >
              <Route index element={<InsightHome />} />
              <Route path="clients" element={<InsightClients />} />
              <Route path="finance" element={<InsightFinance />} />
              <Route path="quality" element={<InsightQuality />} />
              <Route path="service" element={<InsightService />} />
            </Route>
            <Route path="operations" >
              <Route index element={<OperationsHome />} />
              <Route path="create" element={<OperationsQueue />} />
            </Route>
            <Route path="user" >
              <Route index element={<UserList />} />
              <Route path="create" element={<UserCreate />} />
              <Route path="update" element={<UserUpdate />} />
            </Route>
            <Route path="vehicle" >
              <Route index element={<VehicleList />} />
              <Route path="create" element={<VehicleCreate />} />
              <Route path="update" element={<VehicleUpdate />} />
            </Route>
          </Route>

          <Route path="*" element={<LayoutMaintenance />} >
            <Route path="*" element={<MaintenanceGeneral />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
