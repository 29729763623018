import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useExternalScript } from '../../hooks/useExternalScript.hook';
import { InsightService } from '../../services/index';

function emitViewReady (data) {
  const event = new CustomEvent('jsx::insight.condensed::view-loaded', data);
  window.dispatchEvent(event);
  console.log('jsx view-loaded')
}

async function getEntity (payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result;
}

function InsightCondensed () {
  const { setPageName } = useOutletContext();
  const [scoreToday, setScoreToday] = useState({ data: { average_over_max: 0, total_today: 0 } });
  const [clientsToday, setClientsToday] = useState({ data: { average_over_max: 0, total_today: 0 } });
  const [servicesToday, setServicesToday] = useState({ data: { average_over_max: 0, total_today: 0 } });
  const [chargesToday, setChargesToday] = useState({ data: { average_over_max: 0, total_today: 0 } });
  const [serviceQuantityWeekly, setServiceQuantityWeekly] = useState({});
  const [serviceQuantity24h, setServiceQuantity24h] = useState({});
  const condensedScriptStatus = useExternalScript('/assets/js/pages/insight.condensed.js');

  useEffect(() => {
    setPageName('Insights');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const _scoreToday = await getEntity({
        name: '#score-today-admin'
      }, InsightService);
      const _clientsToday = await getEntity({
        name: '#clients-today-admin'
      }, InsightService);
      const _servicesToday = await getEntity({
        name: '#services-today-admin'
      }, InsightService);
      const _chargesToday = await getEntity({
        name: '#charges-today-admin'
      }, InsightService);
      const _serviceQuantityWeekly = await getEntity({
        name: '#service-quantity-weekly-admin'
      }, InsightService);
      const _serviceQuantity24h = await getEntity({
        name: '#service-quantity-24h-admin'
      }, InsightService);

      setScoreToday(_scoreToday);
      setClientsToday(_clientsToday);
      setServicesToday(_servicesToday);
      setChargesToday(_chargesToday);
      setServiceQuantityWeekly(_serviceQuantityWeekly);
      setServiceQuantity24h(_serviceQuantity24h);

      console.log(_serviceQuantity24h.data)
    }

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (condensedScriptStatus === 'ready' && serviceQuantity24h.data) {
      emitViewReady({
        detail: {
          serviceQuantityWeekly,
          serviceQuantity24h
        }
      });
    }
  }, [serviceQuantity24h]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <h4 className="header-title">Genéricas</h4>
        </div>
      </div>

      {/* Principal Insights */}
      <section className="row">

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mt-0 mb-4">Servicios</h4>

              <div className="widget-chart-1">
                <div className="widget-chart-box-1 float-start" dir="ltr">
                  <input data-plugin="knob" data-bgcolor="#F9B9B9" data-fgcolor="#f05050 "
                    data-width="70"
                    data-height="70"
                    value={servicesToday.data.average_over_max === undefined ? 0 : servicesToday.data.average_over_max}
                    readOnly={true}
                    data-skin="tron" data-angleoffset="180" data-readonly="true"
                    data-thickness=".15" />
                </div>

                <div className="widget-detail-1 text-end">
                  <h2 className="fw-normal pt-2 mb-1"> {servicesToday.data.total_today} </h2>
                  <p className="text-muted mb-1">Total hoy</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mt-0 mb-3">Cobros</h4>

              <div className="widget-box-2">
                <div className="widget-detail-2 text-end">
                  <span className="badge bg-success rounded-pill float-start mt-3">{chargesToday.data.average_over_max}% <i className="mdi mdi-trending-up"></i> </span>
                  <h2 className="fw-normal mb-1"> {chargesToday.data.total_today} </h2>
                  <p className="text-muted mb-3">Total hoy</p>
                </div>
                <div className="progress progress-bar-alt-success progress-sm">
                  <div className="progress-bar bg-success" role="progressbar"
                    style={{ width: chargesToday.data.average_over_max }}
                    aria-valuenow={chargesToday.data.average_over_max}
                    aria-valuemin="0" aria-valuemax="100">
                    <span className="visually-hidden">{chargesToday.data.average_over_max}% Complete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mt-0 mb-4">Clientes</h4>

              <div className="widget-chart-1">
                <div className="widget-chart-box-1 float-start" dir="ltr">
                  <input data-plugin="knob" data-width="70" data-height="70" data-fgcolor="#ffbd4a"
                    data-bgcolor="#FFE6BA"
                    value={clientsToday.data.average_over_max}
                    readOnly={true}
                    data-skin="tron" data-angleoffset="180" data-readonly="true"
                    data-thickness=".15" />
                </div>
                <div className="widget-detail-1 text-end">
                  <h2 className="fw-normal pt-2 mb-1"> {clientsToday.data.total_today} </h2>
                  <p className="text-muted mb-1">Activos hoy</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mt-0 mb-3">Calificación</h4>

              <div className="widget-box-2">
                <div className="widget-detail-2 text-end">
                  <span className="badge bg-pink rounded-pill float-start mt-3">{scoreToday.data.average_over_max}% <i className="mdi mdi-trending-up"></i> </span>
                  <h2 className="fw-normal mb-1"> {scoreToday.data.total_today} </h2>
                  <p className="text-muted mb-3">Total hoy</p>
                </div>
                <div className="progress progress-bar-alt-pink progress-sm">
                  <div className="progress-bar bg-pink" role="progressbar"
                    style={{ width: scoreToday.data.average_over_max }}
                    aria-valuenow={scoreToday.data.average_over_max}
                    aria-valuemin="0" aria-valuemax="100">
                    <span className="visually-hidden">{scoreToday.data.average_over_max}% Complete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <div className="row">
        <div className="col-sm-12">
          <h4 className="header-title">Específicas</h4>
        </div>
      </div>
      <section className="row">
        <div className="col-xl-6">
          <section className="card">
            <div className="card-body">
              <h4 className="header-title mt-0">Servicios por horas</h4>
              <p className="sub-header">Últimas 24h</p>

              <div id="simple-line-chart" className="ct-chart ct-golden-section"></div>
            </div>
          </section>
        </div>

        <div className="col-xl-6">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mt-0">Servicios por días</h4>
              <p className="sub-header">Última semana</p>

              <div id="distributed-series" className="ct-chart ct-golden-section"></div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
}

export default InsightCondensed;