import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function OperationsHome () {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Operaciones');
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img className="card-img-top img-fluid" src="/assets/images/side-pages/operations-queue.jpg" alt="Card image cap" />
            <div className="card-body text-center">
              <a href="/admin/operations/queue" className="btn btn-bordered-primary">Cola de conductores</a>
            </div>
          </article>
        </div>
      </div>
    </div>

  );
}

export default OperationsHome;
