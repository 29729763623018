import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function InsightClients () {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Insights');
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <h4 className="header-title">Clientes</h4>
        </div>
      </div>


    </div>
  );
}

export default InsightClients;